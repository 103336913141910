import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic08 from '../assets/images/pic08.jpg'
import sticker10 from '../assets/images/sticker10.png'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const BannerLanding = props => (
  <section id="banner" className="style2">
    <div className="inner">
      <header className="major">
        <h1>ASU, Mobile & IoT</h1>
      </header>
      <div className="content">
        <p>Don't stop coding</p>
      </div>
    </div>
  </section>
)

const ASUInfo = props => (
  <Layout>
    <Helmet>
      <title>ASU, Mobile & IoT</title>
      <meta name="description" content="Mobile & IoT Software Development" />
    </Helmet>

    <BannerLanding />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2 id="construction">Under construction, bud</h2>
          </header>
        </div>
      </section>
    </div>
  </Layout>
)

export default ASUInfo
